import React from "react"
import HeaderImage from "./header-image"
import TwitterIcon from "./twitter-icon"
import { Link } from "gatsby"

const animationDuration = "1s"

const Bio = ({ className, withHeaderImage }) => {
  return (
    <div
      className={`w-full px-4 ${className}`}
      style={{ gridTemplateColumns: "auto 1fr" }}
    >
      <div className="flex flex-wrap items-center justify-center gap-4 max-content-width m-auto pt-2 pb-4">
        <Link to="/">
          <div className={withHeaderImage ? "" : "hide-mobile"}>
            <HeaderImage
              style={{ height: "auto", maxWidth: "15rem", width: "100%" }}
            />
          </div>
        </Link>
        <p style={{ minWidth: "5rem" }}>
          <div className="flex flex-wrap gap-4">
            <Link to="/">
              <strong className="text-4xl mb-4">Giovanni Benussi</strong>
            </Link>
            <div className="hide-mobile">
              <a
                className="flex relative"
                href="https://twitter.com/giovannibenussi"
                target="_blank"
                rel="noreferrer"
              >
                <span
                  className="absolute inline-flex h-8 w-8 mt-1 rounded-md opacity-75"
                  style={{
                    background: "rgb(29, 155, 240)",
                  }}
                ></span>
                <TwitterIcon className="h-8 mt-1 z-10" />
              </a>
            </div>
          </div>
          <div className="hide-mobile">
            <p className="my-2 pt-2">
              Thoughts about{" "}
              <span
                className="badge badge-blue badge-square inline-block motion-safe:animate-bounce"
                style={{ animationDuration }}
              >
                React
              </span>
              ,
              <span
                className="badge badge-green badge-square ml-1 inline-block motion-safe:animate-bounce"
                style={{ animationDuration, animationDelay: "100ms" }}
              >
                Web Development
              </span>
              ,
              <span
                className="badge badge-yellow badge-square ml-1 inline-block motion-safe:animate-bounce"
                style={{ animationDuration, animationDelay: "200ms" }}
              >
                Performance
              </span>
            </p>
          </div>
          {false && (
            <a
              href="https://twitter.com/giovannibenussi"
              target="_blank"
              rel="noreferrer"
            >
              You can follow him on Twitter.
            </a>
          )}
        </p>
      </div>
    </div>
  )
}

export default Bio
